
.Caps-header {
  background-color: hsl(354, 97%, 23%);
  min-height: 5vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 1vmin);
  color: rgb(236, 226, 226);
}

.Caps-link {
  color: #1e086e;
}

